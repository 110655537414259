import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/default-page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "à-propos",
      "style": {
        "position": "relative"
      }
    }}>{`À propos`}<a parentName="h1" {...{
        "href": "#%C3%A0-propos",
        "aria-label": "à propos permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h1>
    <p>{`Je m'appelle Maxime Richard, développeur full-stack basé à Nancy. Curieux de nature, je m'intéresse à tout l'écosystème javascript, j'adore aussi créer des projets web.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b7cb932ce5330e1fc08d7d8b6762aae7/d2602/desk.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.90347490347492%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABZy4UUSUR/8QAGxAAAgEFAAAAAAAAAAAAAAAAAQIAAwQREyL/2gAIAQEAAQUCe5WCuWeDks5zsef/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAEQECECExYf/aAAgBAQAGPwLFMvolF2bP/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIVFhcf/aAAgBAQABPyHTIGHW8g3sQ9D9yZ5o9mRxP//aAAwDAQACAAMAAAAQUB//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EGV//8QAGxABAQADAQEBAAAAAAAAAAAAAREAITGBQWH/2gAIAQEAAT8QCBYs0C+ODYM/VNYB0nuKSOmld7shl2oIwLz8MsEEAaz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Maxime Richard photo setup du bureau",
            "title": "Maxime Richard photo setup du bureau",
            "src": "/static/b7cb932ce5330e1fc08d7d8b6762aae7/af659/desk.jpg",
            "srcSet": ["/static/b7cb932ce5330e1fc08d7d8b6762aae7/8356d/desk.jpg 259w", "/static/b7cb932ce5330e1fc08d7d8b6762aae7/bc760/desk.jpg 518w", "/static/b7cb932ce5330e1fc08d7d8b6762aae7/af659/desk.jpg 1035w", "/static/b7cb932ce5330e1fc08d7d8b6762aae7/51eb8/desk.jpg 1553w", "/static/b7cb932ce5330e1fc08d7d8b6762aae7/33042/desk.jpg 2070w", "/static/b7cb932ce5330e1fc08d7d8b6762aae7/d2602/desk.jpg 4032w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Je suis spécialisé dans la création rapide de logiciels et d'applications web. La méthode Agile m'aide à obtenir des retours rapides de la part des utilisateurs. Développer, résoudre des problèmes et mettre en production rapidement font partie de mes leitmotiv.`}</p>
    <p>{`Au fil des années, je me suis pris de passion pour `}<a parentName="p" {...{
        "href": "https://github.com/zyhou/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`l'open source`}</a>{`. Pour moi, le partage avec les autres est essentiel. Que ce soit derrière un ordinateur ou en tant que speaker à des conférences, j’aime partager mes expériences, mais aussi écouter et apprendre des autres lors de ces différents évènements.`}</p>
    <p>{`Je gère depuis juillet 2019 le `}<a parentName="p" {...{
        "href": "https://aperowebnancy.netlify.app/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Meetup Apéro Web`}</a>{` à Nancy, une fois par mois environ 30-50 développeurs se retrouvent pour parler technique autour d'une bière.`}</p>
    <p>{`J'anime `}<a parentName="p" {...{
        "href": "https://discord.gg/Q8X8Bxq",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Lorraine Tech Hub`}</a>{`, un Discord communautaire qui rassemble les développeurs de Lorraine. Rejoins-nous !`}</p>
    <p>{`Sur mon compte Twitter `}<a parentName="p" {...{
        "href": "https://twitter.com/rmaximedev",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`@rmaximedev`}</a>{`, je partage mes différentes lectures et je documente également mon apprentissage. N'hésitez pas à me suivre !`}</p>
    <blockquote>
      <p parentName="blockquote">{`Pour tous les fans de setup, j'ai créé une page spéciale, suivez-moi par `}<a parentName="p" {...{
          "href": "/setup"
        }}>{`ici`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "travail",
      "style": {
        "position": "relative"
      }
    }}>{`Travail`}<a parentName="h2" {...{
        "href": "#travail",
        "aria-label": "travail permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <p>{`Je travaille chez `}<a parentName="p" {...{
        "href": "https://www.tint.ai/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Tint`}</a>{` depuis Août 2021, une startup à San Francisco qui aide les entreprises qui veulent vendre leur propre produit d'assurance.`}</p>
    <h2 {...{
      "id": "hors-ligne",
      "style": {
        "position": "relative"
      }
    }}>{`Hors-ligne`}<a parentName="h2" {...{
        "href": "#hors-ligne",
        "aria-label": "hors ligne permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <p>{`Lorsque je n'écris pas de code ou que je ne fais pas de veille technique :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`J'ai été un joueur toute ma vie. Je joue principalement à Overwatch et je suis un grand nombre de matchs e-sport.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Je personnalise mes claviers mécaniques et mon environnement de travail Linux/Windows.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Je regarde beaucoup de séries comme Person of Interest, Sherlock, Westworld, The Big Bang Theory, Silicon Valley. J'adore également tous les films du MCU.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Je suis passionné de voyages, avec ma copine nous avons pour objectif de gratter le plus de pays possible sur notre carte du monde.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Je ne suis jamais contre le fait de boire une bière entre amis, surtout lorsqu'il s'agit d'IPA.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "en-ligne",
      "style": {
        "position": "relative"
      }
    }}>{`En ligne`}<a parentName="h2" {...{
        "href": "#en-ligne",
        "aria-label": "en ligne permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/rmaximedev",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/zyhou/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Github`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/maximerichard9/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Linkedin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.twitch.tv/zyhou",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Twitch`}</a></li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`PS: Voici quelques pages qui ne sont pas présentes dans le menu du site, mais qui pourraient vous intéresser :`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/liens"
        }}>{`Liens`}</a>{` - Des liens vers des choses sur lesquelles je travaille`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/infos"
        }}>{`Infos`}</a>{` - Ma biographie et des photos de moi`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      